import React, { useState, useCallback, memo } from "react";
import GenerateLink from "../../helpers/GenerateLink";
import AlgoliaCollection from "../../components/Collection/AlgoliaCollection";
import { ICollectionLayout } from "../../components/Collection/Collection";

interface CustomAlgoliaCollectionProps {
  query: string;
  layout: ICollectionLayout;
  displayAmount: number;
  seeMoreTo: string;
  title: React.ReactNode;
  filter: string;
}

const CustomAlgoliaCollection: React.FC<CustomAlgoliaCollectionProps> = (props) => {
  return (
    <div className="w-full">
      {props.title}
      <div className="mt-4">
        <AlgoliaCollection
          query={props.query}
          layout={props.layout}
          displayAmount={props.displayAmount}
          seeMoreTo={props.seeMoreTo}
          title={null}
          filters={`category:"${props.filter}"`}
        />
      </div>
    </div>
  );
};

const FEATURED_CATEGORIES = {
  SEATS: "Seats",
  BELTS: "Belts",
  RADIATORS: "Radiators",
} as const;

type CategoryKey = keyof typeof FEATURED_CATEGORIES;

const FILTER_OPTIONS = {
  SEATS: ["All", "Ford", "John Deere", "Caterpillar", "Bobcat", "Rhino", "Yamaha", "New Holland"],
  BELTS: ["All", "Universal", "John Deere", "Bobcat", "Husqvarna", "Snapper", "Bad Boy", "Kubota"],
  RADIATORS: ["All", "Case", "Ford", "Allis Chalmers", "New Holland", "Massey Ferguson"],
};

interface ProductSectionItemProps {
  query: CategoryKey;
  title: string;
  selectedFilter: string;
  onFilterChange: (category: CategoryKey, filter: string) => void;
  filterOptions: string[];
  constructedQuery: string;
}

const ProductSectionItem: React.FC<ProductSectionItemProps> = memo(
  ({ query, title, selectedFilter, onFilterChange, filterOptions, constructedQuery }) => {
    const handleFilterClick = (filter: string) => {
      onFilterChange(query, filter);
    };

    const seeMoreLink =
      selectedFilter === "All"
        ? GenerateLink.ForSearch(FEATURED_CATEGORIES[query])
        : GenerateLink.ForSearch(`${selectedFilter} ${FEATURED_CATEGORIES[query]}`);

    return (
      <div className="mb-16 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="relative py-8 bg-gray-900">
          <h3 className="text-3xl font-extrabold text-white px-4 sm:px-6 lg:px-8 text-center uppercase tracking-wide">{title}</h3>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-yellow-400"></div>
        </div>
        <div className="flex flex-wrap gap-3 px-4 sm:px-6 lg:px-8 py-6 bg-gray-50">
          {filterOptions.map((filter) => (
            <button
              key={filter}
              className={`px-5 py-2.5 rounded-md text-sm font-bold transition-all duration-200 
                ${
                  selectedFilter === filter
                    ? "bg-yellow-400 text-gray-900 shadow-md transform scale-105"
                    : "bg-white text-gray-700 border-2 border-gray-300 hover:border-yellow-400 hover:text-yellow-600"
                }`}
              onClick={() => handleFilterClick(filter)}
            >
              {filter}
            </button>
          ))}
        </div>
        <div className="w-full px-4 sm:px-6 lg:px-8 py-6">
          <CustomAlgoliaCollection
            query={constructedQuery}
            layout="CartCarousel"
            displayAmount={7}
            seeMoreTo={seeMoreLink}
            title={<></>}
            filter={FEATURED_CATEGORIES[query]}
          />
        </div>
      </div>
    );
  }
);

const ProductSection: React.FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<Record<CategoryKey, string>>({
    SEATS: "All",
    BELTS: "All",
    RADIATORS: "All",
  });

  const handleFilterChange = useCallback((category: CategoryKey, filter: string) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: filter,
    }));
  }, []);

  const SECTION_CONFIG: { query: CategoryKey; title: string }[] = [
    { query: "SEATS", title: "SEATS" },
    { query: "BELTS", title: "BELTS" },
    { query: "RADIATORS", title: "RADIATORS" },
  ];

  return (
    <div className="w-full max-w-7xl mx-auto py-16">
      {SECTION_CONFIG.map(({ query, title }) => {
        const constructedQuery =
          selectedFilters[query] === "All" ? FEATURED_CATEGORIES[query] : `${selectedFilters[query]} ${FEATURED_CATEGORIES[query]}`;

        return (
          <ProductSectionItem
            key={query}
            query={query}
            title={title}
            selectedFilter={selectedFilters[query]}
            onFilterChange={handleFilterChange}
            filterOptions={FILTER_OPTIONS[query]}
            constructedQuery={constructedQuery}
          />
        );
      })}
    </div>
  );
};

export default ProductSection;

import React, { useState, useEffect, useRef } from "react";
import GenerateLink from "../../helpers/GenerateLink";
import Belts from "../../images/part_type/belt.png";
import Filter from "../../images/part_type/filter.png";
import Glass from "../../images/part_type/glass.png";
import Radiator from "../../images/part_type/radiator.png";
import SealKit from "../../images/part_type/sealkit.png";
import Seat from "../../images/part_type/seat.png";
import RulerContext from "../../contexts/RulerContext";

const CategorySection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const autoScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastManualScrollTime = useRef<number>(Date.now());
  const ruler = React.useContext(RulerContext);
  const visibleItems = ruler.xs ? 1 : ruler.sm ? 2 : 5;

  const categories = [
    { src: Seat, title: "Seat", link: GenerateLink.ForSearch("Seats") },
    { src: Glass, title: "Glass", link: GenerateLink.ForSearch("Glass") },
    { src: SealKit, title: "Seal Kits", link: GenerateLink.ForSearch("Seal Kits") },
    { src: Belts, title: "Belts", link: GenerateLink.ForSearch("Belts") },
    { src: Filter, title: "Filters", link: GenerateLink.ForSearch("Filters") },
    { src: Radiator, title: "Radiators", link: GenerateLink.ForSearch("Radiator") },
  ];

  const manufacturers = [
    { title: "Zbox", link: GenerateLink.ForSearch("Zbox") },
    { title: "John Deere", link: GenerateLink.ForSearch("John Deere") },
    { title: "Caterpillar", link: GenerateLink.ForSearch("Caterpillar") },
    { title: "New Holland", link: GenerateLink.ForSearch("New Holland") },
    { title: "Volvo", link: GenerateLink.ForSearch("Volvo") },
    { title: "Case", link: GenerateLink.ForSearch("Case") },
    { title: "Bush Hog", link: GenerateLink.ForSearch("Bushhog") },
    { title: "Massey Ferguson", link: GenerateLink.ForSearch("Massey Ferguson") },
    { title: "Bobcat", link: GenerateLink.ForSearch("Bobcat") },
    { title: "Ford", link: GenerateLink.ForSearch("Ford") },
  ];

  useEffect(() => {
    if (ruler.xs && autoScroll) {
      const startAutoScroll = () => {
        autoScrollTimeoutRef.current = setTimeout(() => {
          if (Date.now() - lastManualScrollTime.current >= 2500) {
            setCurrentIndex((prevIndex) => {
              const nextIndex = prevIndex + visibleItems;
              return nextIndex >= categories.length ? 0 : nextIndex;
            });
          }
          startAutoScroll();
        }, 2500);
      };

      startAutoScroll();

      return () => {
        if (autoScrollTimeoutRef.current) {
          clearTimeout(autoScrollTimeoutRef.current);
        }
      };
    }
    return;
  }, [ruler.xs, autoScroll, categories.length, visibleItems]);

  const nextSlide = () => {
    lastManualScrollTime.current = Date.now();
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + visibleItems;
      return nextIndex >= categories.length ? 0 : nextIndex;
    });
  };

  const prevSlide = () => {
    lastManualScrollTime.current = Date.now();
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex - visibleItems;
      return nextIndex < 0 ? prevIndex : nextIndex;
    });
  };

  return (
    <>
      <div className="w-full overflow-hidden bg-gradient-to-b from-blue-50 to-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-3xl font-bold text-gray-900">FEATURED PRODUCTS</h3>
            {ruler.xs && (
              <button className="p-2 text-gray-600 hover:text-gray-900 transition-colors" onClick={() => setAutoScroll(!autoScroll)}>
                {autoScroll ? "⏸" : "▶"}
              </button>
            )}
          </div>

          <div className="relative">
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * (100 / visibleItems)}%)` }}
            >
              {categories.map((category, index) => (
                <div key={index} className="flex-shrink-0" style={{ flexBasis: `${100 / visibleItems}%` }}>
                  <a href={category.link} className="block px-2">
                    <div className="relative h-48 rounded-lg overflow-hidden group">
                      <img
                        src={category.src}
                        alt={category.title}
                        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-40 group-hover:bg-opacity-50 transition-all">
                        <div className="absolute bottom-4 left-4 text-white text-xl font-semibold">{category.title}</div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>

            <button
              className="absolute left-2 top-1/2 -translate-y-1/2 bg-white bg-opacity-90 hover:bg-opacity-100 text-gray-800 w-10 h-10 rounded-full flex items-center justify-center shadow-lg transition-all"
              onClick={prevSlide}
            >
              ❮
            </button>
            <button
              className="absolute right-2 top-1/2 -translate-y-1/2 bg-white bg-opacity-90 hover:bg-opacity-100 text-gray-800 w-10 h-10 rounded-full flex items-center justify-center shadow-lg transition-all"
              onClick={nextSlide}
            >
              ❯
            </button>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h3 className="text-3xl font-bold text-gray-900 inline-block relative pb-2">
              SHOP BY FITMENT
              <div className="absolute left-0 bottom-0 w-full h-0.5 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-400"></div>
            </h3>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6">
            {manufacturers.map((manufacturer, index) => (
              <a key={index} href={manufacturer.link} className="block group">
                <div
                  className="bg-white rounded-xl p-6 h-24 flex items-center justify-center transform transition-all duration-300 ease-in-out
                             shadow hover:shadow-xl hover:-translate-y-1 hover:bg-yellow-50
                             border-2 border-transparent hover:border-yellow-400"
                >
                  <span
                    className="text-gray-800 font-medium text-center group-hover:text-yellow-600 transition-colors
                                 text-lg group-hover:font-semibold"
                  >
                    {manufacturer.title}
                  </span>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySection;

import { useMutation, useQuery } from "@shane32/graphql";
import {
  AddressBookDocument,
  AllCustomerReviewsQueryVariables,
  BraintreeClientTokenDocument,
  CustomerDiagramsDocument,
  EditMyShippingAddressDocument,
  EditMyShippingAddressQueryVariables,
  EmptyFiltersSearchResultsQueryVariables,
  FleetMachineDocument,
  LeaveFeedbackQueryVariables,
  MachineManufacturersQueryVariables,
  MachineManufacturerWithModelDiagramsDocument,
  MachineManufacturerWithModelDiagramsQueryVariables,
  MachineModels2QueryVariables,
  OrderQueryVariables,
  OrderSummaryQueryVariables,
  PaymentMethodDocument,
  PaymentMethodQueryVariables,
  PaymentMethodsDocument,
  PreviouslyOrderedProductsDocument,
  ProductPageAlsoPurchasedQueryVariables,
  ProductPageQueryVariables,
  ProductPageRelatedQueryVariables,
  ProductPageReviewsQueryVariables,
  ProductShippingMethodsQueryVariables,
  QrCodeDocument,
  ReviewQueryVariables,
  SearchFiltersQueryVariables,
  SearchQueryVariables,
  ShoppingCartShippingMethodsQueryVariables,
  SimilarItemsSearchQueryVariables,
  ThankYouPageQueryVariables,
  ValidateProductIdQueryVariables,
  WarehouseQueryVariables,
} from "../gql/graphql";
import { AllCustomerReviewsDocument } from "./AllCustomerReviewsQuery.g";
import { CategoriesDocument } from "./CategoriesQuery.g";
import { CountriesDocument } from "./CountriesQuery.g";
import { DefaultPaymentAndShippingDocument } from "./DefaultPaymentAndShippingQuery.g";
import { EmailNotificationsDocument } from "./EmailNotificationsQuery.g";
import { EmptyFiltersSearchResultsDocument } from "./EmptyFilters.g";
import { FeedbackNameDocument } from "./FeedbackNameQuery.g";
import { FleetQueryDocument } from "./FleetQuery.g";
import { LeaveFeedbackDocument } from "./LeaveFeedbackQuery.g";
import { LoginAndSecurityDocument } from "./LoginAndSecurityQuery.g";
import { MachineManufacturersDocument } from "./MachineManufacturersQuery.g";
import { MachineModels2Document } from "./MachineModelsQuery.g";
import { OrdersAndReturnsQueryDocument } from "./OrdersAndReturnsQuery.g";
import { OrderDocument } from "./OrderStatusQuery.g";
import { OrderSummaryDocument } from "./OrderSummaryQuery.g";
import { ProductPageAlsoPurchasedDocument } from "./ProductPageAlsoPurchasedQuery.g";
import { ProductPageDocument } from "./ProductPageQuery.g";
import { ProductPageRelatedDocument } from "./ProductPageRelatedQuery.g";
import { ProductPageReviewsDocument } from "./ProductPageReviewsQuery.g";
import { ProductShippingMethodsDocument } from "./ProductShippingMethodsQuery.g";
import { ReviewDocument } from "./ReviewQuery.g";
import { SearchDocument, SearchFiltersDocument } from "./SearchQuery.g";
import { SimilarItemsSearchDocument } from "./SearchSimilarItemsQuery.g";
import { ShoppingCartDocument } from "./ShoppingCartQuery.g";
import { ShoppingCartShippingMethodsDocument } from "./ShoppingCartShippingMethodsQuery.g";
import { ThankYouPageDocument } from "./ThankYouPageQuery.g";
import { ValidateProductIdDocument } from "./ValidateProductIdQuery.g";
import { WarehouseDocument } from "./WarehouseQuery.g";
import { ZipCodeTemplateDocument } from "./ZipCodeTemplateQuery.g";

/*
 * fetch policies:
 *
 *   cache-first: (default)
 *   - never retrieve from database once the query has been loaded
 *
 *   cache-and-network: //WARNING: this means if you monitor loading, then it will always blink
 *   - load from cache first
 *   - then LOADING = true, and reload from server
 *   - load new data from server
 *
 *   no-cache:
 *   - always load data from server
 *
 *
 * returnPartialData:
 *   false: do not return a data object if there are any errors (default)
 *
 *
 * when authenticating: ....
 *
 */

const Queries = {
  useCategories: () => useQuery(CategoriesDocument, { guest: true }),

  /* return { // Patch for v1 being undefined when retrieving from the cache
        ...ret, loading: ret.loading || !ret.data || !ret.data.v1,
        data: !ret.loading && !ret.error && ret.data && ret.data.v1 ? ret.data : undefined,
    }; */

  useZipCodeTemplate: () =>
    useQuery(ZipCodeTemplateDocument, {
      guest: true,
    }),

  useQueryLoginAndSecurity: () =>
    useQuery(LoginAndSecurityDocument, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryFeedbackName: () =>
    useQuery(FeedbackNameDocument, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryOrdersAndReturns: () =>
    useQuery(OrdersAndReturnsQueryDocument, {
      fetchPolicy: "no-cache",
    }),

  useQueryThankYouPage: (variables: ThankYouPageQueryVariables) =>
    useQuery(ThankYouPageDocument, {
      guest: true,
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryOrderStatus: (variables: OrderQueryVariables) =>
    useQuery(OrderDocument, {
      guest: true,
      variables: variables,
      fetchPolicy: "no-cache",
    }),

  useQueryEmailNotifications: () =>
    useQuery(EmailNotificationsDocument, {
      fetchPolicy: "no-cache",
    }),

  useQueryWarehouse: (variables: WarehouseQueryVariables) =>
    useQuery(WarehouseDocument, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPageRelated: (variables: ProductPageRelatedQueryVariables) =>
    useQuery(ProductPageRelatedDocument, {
      guest: true,
      variables: variables,
    }),

  useQueryLeaveFeedback: (variables: LeaveFeedbackQueryVariables) =>
    useQuery(LeaveFeedbackDocument, {
      fetchPolicy: "no-cache",
      variables: variables,
    }),

  useQueryOrderSummary: (variables: OrderSummaryQueryVariables) =>
    useQuery(OrderSummaryDocument, {
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryValidateProductId: (variables: ValidateProductIdQueryVariables) =>
    useQuery(ValidateProductIdDocument, {
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPage: (variables: ProductPageQueryVariables) =>
    useQuery(ProductPageDocument, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPageAlsoPurchased: (variables: ProductPageAlsoPurchasedQueryVariables) =>
    useQuery(ProductPageAlsoPurchasedDocument, {
      guest: true,
      variables: variables,
    }),

  useQueryProductPageReviews: (variables: ProductPageReviewsQueryVariables) =>
    useQuery(ProductPageReviewsDocument, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductShippingMethods: (skip: boolean, variables: ProductShippingMethodsQueryVariables) =>
    useQuery(ProductShippingMethodsDocument, {
      guest: true,
      fetchPolicy: "no-cache",
      variables: variables,
      skip: skip,
    }),

  useQueryReview: (variables: ReviewQueryVariables) =>
    useQuery(ReviewDocument, {
      fetchPolicy: "no-cache",
      variables: variables,
    }),

  useSearchResults: (variables: SearchQueryVariables) =>
    useQuery(SearchDocument, {
      guest: true,
      variables,
    }),

  useEmptyFilters: (variables: EmptyFiltersSearchResultsQueryVariables) =>
    useQuery(EmptyFiltersSearchResultsDocument, {
      guest: true,
      variables,
    }),

  useSearchSimilarItemsResults: (variables: SimilarItemsSearchQueryVariables, skip: boolean) =>
    useQuery(SimilarItemsSearchDocument, {
      guest: true,
      variables,
      skip: skip,
    }),

  useSearchFiltersResults: (variables: SearchFiltersQueryVariables) =>
    useQuery(SearchFiltersDocument, {
      guest: true,
      variables,
    }),

  useQueryShoppingCart: () =>
    useQuery(ShoppingCartDocument, {
      fetchPolicy: "no-cache",
    }),

  useQueryDefaultPaymentAndShippingInfo: () => useQuery(DefaultPaymentAndShippingDocument),

  useQueryCountries: () =>
    useQuery(CountriesDocument, {
      guest: true,
    }),

  useQueryShoppingCartShippingMethods: (variables: ShoppingCartShippingMethodsQueryVariables, skip: boolean) =>
    useQuery(ShoppingCartShippingMethodsDocument, {
      variables: variables,
      fetchPolicy: "no-cache",
      skip: skip,
    }),

  useQueryShoppingCartShippingMethodsOnDemand: () => {
    const [mutation] = useMutation(ShoppingCartShippingMethodsDocument);
    return {
      refetch: (vars: ShoppingCartShippingMethodsQueryVariables) => mutation({ variables: vars }),
    };
  },

  useQueryAddressBook: () =>
    useQuery(AddressBookDocument, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryPaymentMethods: () =>
    useQuery(PaymentMethodsDocument, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryEditAddressBook: (variables: EditMyShippingAddressQueryVariables) =>
    useQuery(EditMyShippingAddressDocument, {
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryPaymentMethod: (variables: PaymentMethodQueryVariables) =>
    useQuery(PaymentMethodDocument, {
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryBraintreeClientToken: () => useQuery(BraintreeClientTokenDocument),

  useQueryAllCustomerReviews: (variables: AllCustomerReviewsQueryVariables) =>
    useQuery(AllCustomerReviewsDocument, {
      guest: true,
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryFleet: () =>
    useQuery(FleetQueryDocument, {
      fetchPolicy: "no-cache",
    }),

  useQueryFleetMachine: () =>
    useQuery(FleetMachineDocument, {
      guest: true,
    }),

  useQueryMachineModels: (skip: boolean, variables: MachineModels2QueryVariables) =>
    useQuery(MachineModels2Document, {
      guest: true,
      variables: variables,
      skip: skip,
    }),

  useQueryMachineManufacturers: (skip: boolean, variables: MachineManufacturersQueryVariables) => {
    return useQuery(MachineManufacturersDocument, {
      fetchPolicy: "cache-and-network",
      variables: variables,
      skip: skip,
    });
  },

  useQueryPreviouslyOrderedProducts: () => {
    return useQuery(PreviouslyOrderedProductsDocument, {
      fetchPolicy: "cache-and-network",
    });
  },

  useQueryCustomerDiagrams: () =>
    useQuery(CustomerDiagramsDocument, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryMachineManufacturerWithDiagramModels: (variables: MachineManufacturerWithModelDiagramsQueryVariables) => {
    return useQuery(MachineManufacturerWithModelDiagramsDocument, {
      fetchPolicy: "cache-and-network",
      variables: variables,
    });
  },

  useQueryQrCode: (sku: string) => {
    return useQuery(QrCodeDocument, { variables: { sku: sku } });
  },
};

export default Queries;

import * as React from "react";

//required global css files, which may not be referenced elsewhere
import styles from "./App.module.scss";
import "./components/buttons/button-container.scss";
import "./components/buttons/button.scss";
import "./components/cards/card.scss";
import "./components/dividers/divider.scss";
import "./components/flex/flex.scss";
import "./components/fonts/font.scss";
import "./components/headers/header.scss";
import "./components/margins/margin.scss";
import "./components/padding/padding.scss";
import "./components/rows/row.scss";
import "./components/spacers/spacer.scss";
import "./components/utilities/Alignment.scss";
import "./components/utilities/Animation.scss";
import "./components/utilities/Border.scss";
import "./scss/custom.scss";
import "./scss/images.scss";
import "./scss/images/paymentmethods.scss";
import "./scss/masks.scss";

//force loading in main chunk due to warning about unknown order of loading css
import "./components/address/AddressForm.module.scss";
import "./components/braintree_hosted_fields/HostedFields.module.scss";
import "./components/buttons/LoadingButton.module.scss";
import "./components/inputs/Checkbox.module.scss";
import "./components/inputs/FormTitle.module.scss";
import "./components/inputs/OptionList.module.scss";
import "./components/inputs/Select.module.scss";
import "./components/inputs/Validation.scss";
import "./components/minimize/MinimizeContainer.module.scss";
import "./components/modal/Modal.module.scss";
import "./components/modal/ModalBody.module.scss";
import "./components/modal/ModalFooter.module.scss";
import "./components/modal/ModalHeader.module.scss";
import "./components/navbar/AlternativeNav.module.scss";
import "./components/stock_status/StockStatus.module.scss";
import "./pages/account/OrdersAndReturns/CancelOrder/ConfirmModal.module.scss";
import "./pages/account/OrdersAndReturns/TrackPackage/TrackPackageModal.module.scss";
import "./pages/checkout/Payment/PaymentDetails.module.scss";
import "./pages/checkout/ThankYou/ThankYouItemCard.module.scss";

import { Switch, useLocation } from "react-router-dom";
import Impersonate from "./authentication/Impersonate";
import ImpersonationBar from "./authentication/ImpersonationBar";
import Footer from "./components/footer/Footer";
import DefaultSuspense from "./components/loading/DefaultSuspense";
import Navbar from "./components/navbar/Navbar";
import Overlay from "./components/overlay/Overlay";
import { Route } from "./components/router/Route";
import AutoCompleteContainer from "./components/search_bar/AutoComplete/AutoCompleteContainer";
import { ApiBaseUrl, GraphQlBaseUrl } from "./globalVariables";
import GenerateRoute from "./helpers/GenerateRoute";
import useStructuredData from "./hooks/useStructuredData";
import useTrackers from "./hooks/useTrackers";
import ZboxLogo from "./images/logo/Zbox-logo.png";
import AlgoliaSearch from "./pages/algolia_search/AlgoliaSearch";
import Category from "./pages/category/Category";
import NotFound from "./pages/errorpage/NotFound";
import HomeController from "./pages/home/HomeController";

const Documentation = React.lazy(() => import("./pages/documentation/Documentation"));
const GraphiQL = React.lazy(() => import("./pages/GraphiQL"));
const Checkout = React.lazy(() => import("./pages/checkout/Checkout"));
const ThankYou = React.lazy(() => import("./pages/checkout/ThankYou/ThankYou"));
const RigsListingsController = React.lazy(() => import("./pages/account/Fleet/RigsListingsController"));
const Vendor = React.lazy(() => import("./pages/vendorpage/Vendor"));
const AllCustomerReviewsController = React.lazy(() => import("./pages/productpage/all_customer_reviews/AllCustomerReviewsController"));
const ShoppingCartController = React.lazy(() => import("./pages/shoppingcart/ShoppingCartController"));
const ProductController = React.lazy(() => import("./pages/productpage/ProductController"));
const SearchResultsController = React.lazy(() => import("./pages/searchresults/SearchResultsController"));
const MachinePage = React.lazy(() => import("./pages/account/Fleet/MachinePage/MachinePage"));
const MachineListPage = React.lazy(() => import("./pages/account/Fleet/MachinePage/MachineListPage"));
const EditMachinePage = React.lazy(() => import("./pages/account/Fleet/EditMyMachine/EditMachinePage"));
const QrCode = React.lazy(() => import("./pages/QrCode"));
const DiagramBrand = React.lazy(() => import("./pages/parts_book/DiagramBrand/DiagramBrand"));
const DiagramPage = React.lazy(() => import("./pages/parts_book/MachineDiagramPage/DiagramPage"));
const PartsBooks = React.lazy(() => import("./pages/parts_book/PartsBooks/PartsBooks"));

// account pages
const SignIn = React.lazy(() => import("./pages/account/SignIn/SignIn"));
const Overview = React.lazy(() => import("./pages/account/Overview/Overview"));
const LoginAndSecurityController = React.lazy(() => import("./pages/account/LoginAndSecurity/LoginAndSecurityController"));
const PaymentMethodsController = React.lazy(() => import("./pages/account/PaymentMethods/PaymentMethodsController"));
const AddressBook = React.lazy(() => import("./pages/account/AddressBook/AddressBook"));
const EmailNotifications = React.lazy(() => import("./pages/account/EmailNotifications/EmailNotifications"));
const OrdersAndReturnsController = React.lazy(() => import("./pages/account/OrdersAndReturns/OrdersAndReturnsController"));
const ReturnRequestController = React.lazy(() => import("./pages/account/OrdersAndReturns/ReturnRequest/ReturnRequestController"));
const LeaveFeedbackController = React.lazy(() => import("./pages/account/LeaveFeedback/LeaveFeedbackController"));
const CancelOrderController = React.lazy(() => import("./pages/account/OrdersAndReturns/CancelOrder/CancelOrderController"));
const OrderDetails = React.lazy(() => import("./pages/account/OrdersAndReturns/OrderDetails/OrderDetails"));
const ReviewsController = React.lazy(() => import("./pages/account/OrdersAndReturns/Reviews/ReviewsController"));
const PasswordRecovery = React.lazy(() => import("./pages/account/SignIn/PasswordRecovery"));
const OrderStatus = React.lazy(() => import("./pages/OrderStatus"));
const Fleet = React.lazy(() => import("./pages/account/Fleet/Fleet"));
const LeaveFeedbackListController = React.lazy(() => import("./pages/account/LeaveFeedback/LeaveFeedbackController"));
const MyFleet = React.lazy(() => import("./pages/account/Fleet/MyFleet/MyFleet"));

// help pages
const Help = React.lazy(() => import("./pages/help/Help"));
const Team = React.lazy(() => import("./pages/teampage/Team"));
const Career = React.lazy(() => import("./pages/teampage/Career"));
const About = React.lazy(() => import("./pages/aboutpage/About"));
const SellWithUs = React.lazy(() => import("./pages/sellwithus/SellWithUs"));
const Returns = React.lazy(() => import("./pages/returns/Extended"));

const App = (props: { children?: JSX.Element | JSX.Element[] | null }) => {
  useStructuredData({
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: process.env.REACT_APP_PUBLIC_URL,
    potentialAction: {
      "@type": "SearchAction",
      target: `${process.env.REACT_APP_PUBLIC_URL}/search/s/p?search={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  });
  useStructuredData({
    "@context": "http://schema.org",
    "@type": "Store",
    name: "Zbox",
    description: process.env.REACT_APP_DESCRIPTION,
    url: process.env.REACT_APP_PUBLIC_URL,
    logo: `${process.env.REACT_APP_PUBLIC_URL}${ZboxLogo}`,
    image: `${process.env.REACT_APP_PUBLIC_URL}${ZboxLogo}`,
    openingHours: `${process.env.REACT_APP_PhoneOpenMeta}`,
    telephone: process.env.REACT_APP_PHONE_NUMBER,
    address: {
      "@type": "PostalAddress",
      streetAddress: "3120 Denton Street",
      addressLocality: "Hamtramck",
      addressRegion: "MI",
      addressCountry: "USA",
    },
    foundingDate: "2017",
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Support",
      telephone: process.env.REACT_APP_PHONE_NUMBER,
    },
  });

  // send page changes to google analytics
  const tracker = useTrackers();
  const location = useLocation();
  const trackerNewUri = tracker.newUri;
  const uri = location.pathname + (location.search || "");
  React.useEffect(() => {
    trackerNewUri(uri);
  }, [uri, trackerNewUri]);

  return (
    <DefaultSuspense>
      <Switch>
        <Route
          path={GenerateRoute.ForGraphiQL()}
          render={() => <GraphiQL baseUrl={ApiBaseUrl} apiUrl={GraphQlBaseUrl} />}
          title="GraphiQL"
        />
        <Route path={GenerateRoute.ForSearch()} component={AlgoliaSearch} title="Search" />
        <Route exact path={GenerateRoute.ForHome()} component={HomeController} title={process.env.REACT_APP_TAGLINE || ""} />
        <Route exact path={GenerateRoute.ForDiagram()} component={DiagramPage} title="Diagram" />
        {/* <Route path={GenerateRoute.ForMobileAutoCompleteSearchParam()} component={MobileAutoCompleteContainer} title="Search Suggest" />
            <Route path={GenerateRoute.ForMobileAutoCompleteSearch()} component={MobileAutoCompleteContainer} title="Search Suggest" /> */}
        <Route title={process.env.REACT_APP_TAGLINE || ""}>
          <div className={styles["all-contents"]}>
            <Overlay></Overlay>
            <ImpersonationBar />
            <Navbar>
              <AutoCompleteContainer />
            </Navbar>
            <div className={styles.container}>
              <DefaultSuspense>
                {props.children ? (
                  props.children
                ) : (
                  <Switch>
                    <Route exact path={GenerateRoute.ForAllCategory()} component={Category} title="" />
                    <Route exact path={GenerateRoute.ForCategory()} component={Category} title="" />
                    <Route path={GenerateRoute.ForSearchCategory()} component={SearchResultsController} title="" />
                    <Route exact path={GenerateRoute.ForProduct()} component={ProductController} title="" />
                    <Route path={GenerateRoute.MachineMfgsPage()} component={MachineListPage} title="" />
                    <Route exact path={GenerateRoute.MachinePage()} component={MachinePage} title="" />
                    <Route exact path={GenerateRoute.MyMachinePage()} component={MachinePage} title="" />
                    <Route exact path={GenerateRoute.EditMachinePage()} component={EditMachinePage} title="" />
                    <Route path={GenerateRoute.ForSearchProduct()} component={SearchResultsController} title="" />
                    <Route exact path={GenerateRoute.ForSignIn()} component={SignIn} title="Sign In" />
                    <Route exact path={GenerateRoute.ForPasswordRecovery()} component={PasswordRecovery} title="Reset Password" />
                    <Route exact path={GenerateRoute.ForTeam()} component={Team} title="Team" />
                    <Route exact path={GenerateRoute.ForCareer()} component={Career} title="Career" />
                    <Route exact path={GenerateRoute.ForAbout()} component={About} title="About" />
                    <Route exact path={GenerateRoute.ForSellWithUs()} component={SellWithUs} title="Sell With Us" />
                    <Route exact path={GenerateRoute.ForVendor()} component={Vendor} title="Vendor" />
                    <Route exact path={GenerateRoute.ForAccount()} component={Overview} title="Overview" />
                    <Route path={GenerateRoute.ForAccountSecurity()} component={LoginAndSecurityController} title="Security" />
                    <Route exact path={GenerateRoute.ForOrders()} component={OrdersAndReturnsController} title="Orders and Returns" />
                    <Route path={GenerateRoute.ForFilteredOrders()} component={OrdersAndReturnsController} title="Orders and Returns" />
                    <Route exact path={GenerateRoute.ForOrderDetails()} component={OrderDetails} title="Order Details" />
                    <Route exact path={GenerateRoute.ForRma()} component={ReturnRequestController} title="Return Request" />
                    <Route exact path={GenerateRoute.ForCancel()} component={CancelOrderController} title="Cancel Order" />
                    <Route
                      exact
                      path={GenerateRoute.ForFeedback("Standard")}
                      component={LeaveFeedbackListController}
                      title="Leave Feedback"
                    />
                    <Route exact path={GenerateRoute.ForFeedback("Id")} component={LeaveFeedbackController} title="Leave Feedback" />
                    <Route exact path={GenerateRoute.ForEmailNotifications()} component={EmailNotifications} title="Email Notifications" />
                    <Route
                      exact
                      path={GenerateRoute.ForPaymentMethods("Standard")}
                      component={PaymentMethodsController}
                      title="Payment Methods"
                    />
                    <Route
                      exact
                      path={GenerateRoute.ForPaymentMethods("Id")}
                      component={PaymentMethodsController}
                      title="Payment Methods"
                    />
                    <Route
                      exact
                      path={GenerateRoute.ForPaymentMethods("Add")}
                      component={PaymentMethodsController}
                      title="Payment Methods"
                    />
                    <Route
                      exact
                      path={GenerateRoute.ForPaymentMethods("IdAndDelete")}
                      component={PaymentMethodsController}
                      title="Payment Methods"
                    />
                    <Route exact path={GenerateRoute.ForAddressBook("Add")} component={AddressBook} title="Address Book Add" />
                    <Route exact path={GenerateRoute.ForAddressBook("Standard")} component={AddressBook} title="Address Book" />
                    <Route exact path={GenerateRoute.ForAddressBook("Id")} component={AddressBook} title="Address Book" />
                    <Route exact path={GenerateRoute.ForAddressBook("IdAndDelete")} component={AddressBook} title="Address Book" />
                    <Route exact path={GenerateRoute.MyFleet()} component={MyFleet} title="My Fleet" />
                    <Route exact path={GenerateRoute.ForFleet("Standard")} component={Fleet} title="Fleet" />
                    <Route exact path={GenerateRoute.ForFleet("Add")} component={Fleet} title="Fleet" />
                    <Route exact path={GenerateRoute.ForFleetRigsListings()} component={RigsListingsController} title="Rigs Listings" />
                    <Route exact path={GenerateRoute.ForPartsBooks()} component={PartsBooks} title="Parts Books" />
                    <Route path={GenerateRoute.ForDiagramBrand()} component={DiagramBrand} title="Diagram Manufacturer" />
                    <Route exact path={GenerateRoute.ForCartReward()} component={ShoppingCartController} title="Shopping Cart" />
                    <Route exact path={GenerateRoute.ForCart()} component={ShoppingCartController} title="Shopping Cart" />
                    <Route path={GenerateRoute.ForHelp()} component={Help} title="Help" />
                    <Route exact path={GenerateRoute.ForReviews()} component={ReviewsController} title="Reviews" />
                    <Route
                      exact
                      path={GenerateRoute.ForAllCustomerReviews()}
                      component={AllCustomerReviewsController}
                      title="All Customer Reviews"
                    />
                    <Route exact path={GenerateRoute.ForCheckout("THANK_YOU")} component={ThankYou} title="Thank You" />
                    <Route path={GenerateRoute.ForCheckout(null)} component={Checkout} title="Checkout" />
                    <Route path={GenerateRoute.ForDocumentation()} component={Documentation} title="Documentation" />
                    <Route exact path={GenerateRoute.ForImpersonate()} component={Impersonate} title="Impersonate" />
                    <Route exact path={GenerateRoute.ForUnimpersonate()} component={Impersonate} title="Impersonate" />
                    <Route exact path={GenerateRoute.ForOrderStatus()} component={OrderStatus} title="Order Status" />
                    <Route exact path={GenerateRoute.ForReturns("ExtReturn")} component={Returns} title="Extended Return Registration" />
                    <Route exact path={GenerateRoute.ForReturns("ExtReturn1")} component={Returns} title="Extended Return Registration" />
                    <Route exact path={GenerateRoute.ForReturns("ExtReturns")} component={Returns} title="Extended Return Registration" />
                    <Route exact path={GenerateRoute.ForReturns("ExtReturns2")} component={Returns} title="Extended Return Registration" />
                    <Route exact path={GenerateRoute.ForQrCode()} component={QrCode} title="QR Code Link" />
                    <Route title="Page Not Found" component={NotFound} />
                  </Switch>
                )}
              </DefaultSuspense>
            </div>
            <Footer />
          </div>
        </Route>
      </Switch>
    </DefaultSuspense>
  );
};

export default App;

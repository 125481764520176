import * as React from "react";
import Queries from "../queries/Queries";
import usePaymentScripts from "../hooks/usePaymentScripts";

export const BraintreeContext = React.createContext<IBraintreeContext>({
  loaded: false,
  errorLoading: false,
  errorLoadingMessage: null,
  braintreeClient: null,
  deviceData: null,
  braintree: null,
  startLoading: () => {},
});

// CartContainer is used so our context has state and brains
export const BraintreeContainer = (props: { children?: React.ReactNode }) => {
  const [load, setLoad] = React.useState<boolean>(false);
  const trackers = usePaymentScripts(!load);
  const braintree = trackers.braintree;
  const paypalLoading = !trackers.payPalLoaded;
  const googlePayLoading = !trackers.googlePayLoaded;

  const { loading, error, data } = Queries.useQueryBraintreeClientToken();
  //if (error) return error.message;
  const braintreeClientToken = (!loading && !error && data?.v1.my?.plugins?.braintree?.clientToken) || null;
  const startLoadingCallback = React.useCallback(() => {
    if (!load) setLoad(true);
  }, [load]);
  const [state, setState] = React.useState<IBraintreeContext>({
    loaded: false,
    braintreeClient: null,
    errorLoading: false,
    errorLoadingMessage: null,
    deviceData: null,
    braintree: braintree,
    startLoading: startLoadingCallback,
  });

  React.useEffect(() => {
    if (braintreeClientToken && !paypalLoading && !googlePayLoading && braintree) {
      let localBraintreeClient: { teardown: () => void } | null = null;
      let disposing: boolean = false;
      braintree.client.create(
        {
          authorization: braintreeClientToken,
        },
        (clientErr, clientInstance) => {
          if (disposing) {
            if (clientInstance) clientInstance.teardown();
            return;
          }
          if (clientErr) {
            console.error("Error initializing braintree client", clientErr);
            alert("Error building payment fields.");
            setState({
              loaded: false,
              braintreeClient: null,
              errorLoading: true,
              errorLoadingMessage: clientErr.message,
              deviceData: null,
              braintree: braintree,
              startLoading: startLoadingCallback,
            });
            return;
          }
          localBraintreeClient = clientInstance;

          // initialize data collector
          braintree!.dataCollector.create(
            {
              client: clientInstance,
              kount: true,
              paypal: true,
            },
            function (dataCollectorErr, dataCollectorInstance) {
              if (disposing) return;

              if (dataCollectorErr || !dataCollectorInstance) {
                // Handle error in creation of data collector
                console.error("Error performing braintree data collection", dataCollectorErr);
                setState({
                  loaded: false,
                  braintreeClient: null,
                  errorLoading: true,
                  errorLoadingMessage: dataCollectorErr?.message || "Unknown error",
                  deviceData: null,
                  braintree: braintree,
                  startLoading: startLoadingCallback,
                });
                return;
              }

              if (localBraintreeClient) {
                //not disposed of this instance yet
                // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                // to your server, e.g. by injecting it into your form as a hidden input.
                setState({
                  loaded: true,
                  braintreeClient: localBraintreeClient,
                  errorLoading: false,
                  errorLoadingMessage: null,
                  deviceData: dataCollectorInstance.deviceData,
                  braintree: braintree,
                  startLoading: startLoadingCallback,
                });
              }
            }
          );
        }
      );
      return () => {
        disposing = true;
        if (localBraintreeClient) {
          localBraintreeClient.teardown();
          localBraintreeClient = null;
        }
        setState({
          loaded: false,
          braintreeClient: null,
          errorLoading: false,
          errorLoadingMessage: null,
          deviceData: null,
          braintree: braintree,
          startLoading: startLoadingCallback,
        });
      };
    } else {
      //no cleanup function
      return;
    }
  }, [braintreeClientToken, paypalLoading, googlePayLoading, braintree, startLoadingCallback]);

  return <BraintreeContext.Provider value={state}>{props.children}</BraintreeContext.Provider>;
};

export interface IBraintreeContext {
  loaded: boolean;
  errorLoading: boolean;
  errorLoadingMessage: string | null;
  braintreeClient: any | null;
  deviceData: string | null;
  braintree: typeof import("braintree-web") | null;
  startLoading: () => void;
}

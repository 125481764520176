import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import NavSidebar from "../../components/navbar/NavSideBar";
import Overlay from "../../components/overlay/Overlay";
import useHeader from "../../hooks/useHeader";
import CategorySection from "./CategorySection";
import ProductSection from "./ProductSection";

const Home = () => {
  useHeader("ScrollNavBar");
  return (
    <div className="min-h-screen bg-gray-100">
      <Overlay />
      <NavSidebar />
      <div className="flex flex-col">
        <div className="w-full bg-gray-900">
          <Navbar />
        </div>

        {/* Hero Section */}
        <div
          className="relative isolate px-6 pt-14 lg:px-8 bg-cover bg-center overflow-hidden"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(36, 39, 44, 0.9), rgba(36, 39, 44, 0.7)), url(${require("../../images/herosectionbackground.jpg")})`,
          }}
        >
          <div className="mx-auto max-w-7xl py-16 sm:py-24 lg:py-32 relative">
            <div className="max-w-4xl">
              <div className="sm:mb-8 sm:flex sm:justify-start">
                <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-300 bg-gray-900/10 backdrop-blur-sm ring-1 ring-white/10 hover:ring-white/20">
                  Save your machine to{" "}
                  <a href="/MyFleet" className="font-semibold text-[#FFE81A] hover:text-[#EEDA2B]">
                    <span className="absolute inset-0" aria-hidden="true"></span>My Fleet <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
              <h1 className="text-5xl font-bold tracking-tight text-balance text-white drop-shadow-lg sm:text-7xl mt-6">
                Heavy-Duty Parts for Heavy-Duty Performance
              </h1>
              <p className="mt-8 text-lg font-medium text-pretty text-gray-300 drop-shadow sm:text-xl/8">
                Quality parts for construction, agricultural, and industrial machinery. Built to last, delivered fast.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="/search"
                  className="rounded-full bg-[#FFE81A] px-8 py-3 text-base font-semibold text-gray-900 shadow-lg hover:bg-[#EEDA2B] transition-all duration-200 transform hover:scale-105"
                >
                  Find Your Parts
                </a>
                <a href="/about" className="text-base font-semibold text-gray-300 hover:text-white">
                  Learn more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <main className="flex-grow bg-gray-100">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-b from-gray-900/10 to-transparent opacity-50" />
            <div className="relative">
              <CategorySection />
              <ProductSection />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import { IProductShoppingCart } from "../../models/product/IProductShoppingCart";
import defaultImage from "../../pages/productpage/images/no_image_avail.jpg";
import { IManufacturer } from "../../queries/ThankYouPageQuery";
import Input from "../inputs/Input";
import Select from "../inputs/Select";
import StockStatus from "../stock_status/StockStatus";
import styles from "./ItemContainer.module.scss";

interface IPropsShoppingCart {
  listingType: "SHOPPING_CART";
  item: IProductShoppingCart;
  //estimatedTaxRate: number | null;
  backordered: boolean;
  quantity: number;
  reference: string | null;
  lineReference?: string;
  showLineReference: boolean;
  //selectedQuantities: { [id: string]: number };
  quantityText?: string | null;
  //inputQuantity: string;
  buttonsDisabled?: boolean;
  onHandleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  onSelectQuantity: (e: React.FormEvent<HTMLSelectElement>) => void;
  onRemoveItem: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveForLater: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveInputQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  referenceText?: string | null;
  editingRef?: boolean;
  onSaveLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
  onChangeReferenceInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
}

interface IPropsOrderReview {
  listingType: "ORDER_REVIEW";
  item: IProductShoppingCart;
  backordered: boolean;
  quantity: number;
  reference: string | null;
  lineReference?: string;
  showLineReference: boolean;
  quantityText?: string | null;
  buttonsDisabled?: boolean;
  onHandleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  onSelectQuantity: (e: React.FormEvent<HTMLSelectElement>) => void;
  onRemoveItem: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveForLater: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveInputQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  referenceText?: string | null;
  editingRef?: boolean;
  onSaveLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
  onChangeReferenceInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
}
export default function ShoppingCartItemContainer(prop: { prop: IPropsShoppingCart | IPropsOrderReview }) {
  let additionalDetails: Array<JSX.Element | null> = [];
  let shoppingDetails: Array<JSX.Element | null> = [];
  let returnDetails: Array<JSX.Element | null> = [];
  let price: number | null = null;
  const props = prop.prop;
  price = props.item.priceAndAvailability.price;

  additionalDetails.push(
    props.listingType === "SHOPPING_CART" ? null : (
      <div className={`${styles["brand"]} ${styles["default-field"]}`} key={"BRAND"}>
        Brand: {(props.item.manufacturers as IManufacturer[]).map((x) => x.manufacturer.name).join(", ")}
      </div>
    )
  );

  additionalDetails.push(
    <div className={styles["default-field"]} key={"SOLD_BY"}>
      Sold by: <span className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</span>
    </div>
  );

  additionalDetails.push(
    <div className={styles["default-field"]} key={"PRICE_STATUS"}>
      <StockStatus status={props.backordered ? "BACKORDERED" : props.item.priceAndAvailability.status} />
    </div>
  );

  let quantitySelect: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  shoppingDetails.push(
    <div key={"CART_ROW"} className={styles.inputs}>
      <div className={`${styles["default-field"]} ${styles["card-inputs"]}`}>
        <span className={styles.quantity}>Quantity:</span>
        <span className={styles.qty}>Qty:</span>
        {props.onSelectQuantity !== undefined ? (
          props.quantity >= 10 ? (
            <React.Fragment>
              <Input
                value={props.quantityText === "" ? "" : props.quantityText || props.quantity + ""}
                onKeyPress={props.onHandleKeyPress}
                onChange={props.onChangeQuantity}
                onBlur={props.onSaveInputQuantity}
                id={props.item.id}
                disabled={props.buttonsDisabled}
                inputMode="numeric"
              />
            </React.Fragment>
          ) : (
            <Select
              onChange={props.onSelectQuantity}
              id={props.item.id}
              value={props.quantity}
              disabled={props.buttonsDisabled || props.item.priceAndAvailability.orderMaximumQuantity === 1}
            >
              {quantitySelect
                .filter((x) => x <= props.item.priceAndAvailability.orderMaximumQuantity)
                .map((select, index) => (
                  <option key={index} value={select}>
                    {select === 10 ? "10+" : select}
                  </option>
                ))}
            </Select>
          )
        ) : null}
        {props.onRemoveItem !== undefined ? (
          <button
            className="btn secondary w-small fs-small short"
            onClick={props.onRemoveItem}
            id={props.item.id}
            disabled={props.buttonsDisabled}
          >
            REMOVE
          </button>
        ) : null}
        {props.onSaveForLater !== undefined && price != null && price > 0 ? (
          <button
            className="btn secondary w-small fs-small short"
            onClick={props.onSaveForLater}
            id={props.item.id}
            disabled={props.buttonsDisabled}
          >
            SAVE FOR LATER
          </button>
        ) : null}
      </div>
      {props.showLineReference ? (
        props.reference && !props.editingRef ? (
          <div className={`${styles["line-reference"]} ${styles["set-reference"]}`}>
            <label>Reference:</label>
            <div title="Reference:">{props.referenceText}</div>
            <button type="submit" className="btn secondary w-small fs-small short" onClick={props.onEditLineReference}>
              EDIT
            </button>
          </div>
        ) : (
          <div className={styles["line-reference"]}>
            <Input inlineLabel title="Reference:" value={props.referenceText} onChange={props.onChangeReferenceInput} />
            <button type="submit" className="btn secondary w-small fs-small short" onClick={props.onSaveLineReference}>
              SAVE
            </button>
          </div>
        )
      ) : null}
    </div>
  );

  return (
    <div className={`${styles["image-details-container"]} ${styles["shopping-cart"]}`}>
      {returnDetails}
      <div className={styles["image-container"]}>
        <Link to={GenerateLink.ForProduct(props.item.id, props.item.seoName, undefined)}>
          <img src={props.item.picture?.thumbnail?.url || defaultImage} alt="" />
        </Link>
      </div>
      <div className={styles["details-container"]}>
        {props.listingType === "SHOPPING_CART" && price != null && price > 0 ? (
          <Link className={styles["item-name"]} to={GenerateLink.ForProduct(props.item.id, props.item.seoName, undefined)}>
            {props.item.name}
          </Link>
        ) : (
          <div className={`${styles["non-clickable"]}`}>{props.item.name}</div>
        )}
        {price != null && price > 0 ? (
          <>
            <div className={styles.price}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              }).format(price)}
            </div>
            {additionalDetails}
          </>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: "#248122",
            }}
          >
            {" "}
            Free
          </div>
        )}
      </div>
      {shoppingDetails}
    </div>
  );
}

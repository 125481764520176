import * as React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import { IProductSearchResult } from "../../models/product/IProductSearchResult";
import defaultImage from "../../pages/productpage/images/no_image_avail.jpg";
import { IManufacturer } from "../../queries/ThankYouPageQuery";
import StockStatus from "../stock_status/StockStatus";
import styles from "./SearchResultItem.module.scss";

interface IPropsSearchResult {
  item: IProductSearchResult;
  searchString?: string;
  interchange?: string | null;
  fitment?: string | null;
}

const SearchResultItem = React.memo((props: IPropsSearchResult) => {
  // this is a purecomponent -- DO NOT USE STATE OR CONTEXT HERE
  let additionalDetails: Array<JSX.Element | null> = [];
  let price: number | null = null;
  let dollars: string[] | null = null;
  let cents: string | null = null;

  price = props.item.priceAndAvailability.price;

  if (price !== null) {
    const priceString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 4,
    }).format(price);
    const priceSplit = priceString.split(".");
    cents = priceSplit[1];
    dollars = priceSplit[0].split("$");
  }

  //Add manufacturer field {/*2/2/21 AB TESTING to revert later*/}
  additionalDetails.push(
    <div className={`${styles["brand"]} ${styles["default-field"]}`} key={"BRAND"}>
      Brand: {(props.item.manufacturers as IManufacturer[]).map((x) => x.manufacturer.name).join(", ")}
    </div>
  );

  !!props.item.warehouse &&
    additionalDetails.push(
      <div className={`${styles["brand"]} ${styles["default-field"]}`} key={"WAREHOUSE"}>
        Warehouse: {props.item.warehouse.address?.city ? props.item.warehouse.address?.city + "," : null}{" "}
        {props.item.warehouse.address?.stateProvince?.abbreviation || null}
      </div>
    );

  additionalDetails.push(
    <div className={styles["details-container"]} key={"SEARCH_RESULT"}>
      <div className={styles["search-result-price"]}>
        {price !== null && (
          <div className={styles.superscript}>
            <span>$</span>
            {dollars}
            <span>{cents}</span>
          </div>
        )}
        {!!props.item.additionalShippingCharge && (
          <span className={styles["default-field"]}>
            +{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.item.additionalShippingCharge)}{" "}
            shipping
          </span>
        )}
        {!!props.item.priceAndAvailability.status && (
          <div className="ml-10 mb-5">
            <StockStatus status={props.item.priceAndAvailability.status} />
          </div>
        )}
      </div>
    </div>
  );

  //const price = props.listingType !== 'SEARCH_RESULT' && props.listingType !== 'FEEDBACK' ? (props.price !== undefined ? props.price : props.item.priceAndAvailability.price) : null;
  return (
    <div className={styles["image-details-container"]} data-insights-object-id={props.item.id} data-insights-query={props.searchString}>
      <div className={styles["image-container"]}>
        <Link to={GenerateLink.ForProduct(props.item.id, props.item.seoName, props.searchString || undefined)}>
          <img src={props.item.picture?.thumbnail?.url || defaultImage} alt="" />
        </Link>
      </div>
      <div className={styles["details-container"]}>
        <Link
          className={styles["item-name"]}
          to={GenerateLink.ForProduct(props.item.id, props.item.seoName, props.searchString || undefined)}
        >
          {props.item.name}
        </Link>
        {additionalDetails}
        {props.interchange ? (
          <div className={styles.interchange}>
            Direct interchange with <span>{props.interchange}</span>
          </div>
        ) : null}
        {props.fitment ? (
          <div className={styles.interchange}>
            Fits <span>{props.fitment}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default SearchResultItem;

/* Purchased Item :
 * Picture - ALL
 * Number: Name - ALL
 * Price - ALL
 * Brand - CANCEL, CHECKOUT, PACKSLIP, RETURN_REQUEST, RETURN_STATUS, SEARCH_RESULT
 * Sold By - CANCEL, CHECKOUT, ORDER_STATUS, PACKSLIP, RETURN_REQUEST, RETURN_STATUS, TRACK_PACKAGE
 * Add Rating - FEEDBACK
 * Quantity - CANCEL, ORDER_STATUS, RETURN_REQUEST, RETURN_STATUS, PACKSLIP, TRACK_PACKAGE
 * Reference - CANCEL, CHECKOUT
 * Return Quantity - RETURN_REQUEST_FIELD
 * Reason for Return - RETURN_REQUEST_FIELD
 */

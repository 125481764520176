import * as React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import { IRelatedProduct } from "../../queries/ProductPageRelatedQuery";
import styles from "./RelatedItems.module.scss";
import noItem from "../../images/products/no-image-available.png";

interface IProps {
  // seemore Route:!!!!
  title: React.ReactNode;
  relatedItems: IRelatedProduct[] | null;
  seeMoreTo: string;
}

const ProductCarousel = (props: IProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState<number | null>(null); //null until the div has loaded

  const onResize = React.useCallback(() => {
    if (ref.current) setWidth(ref.current.offsetWidth);
  }, []);
  React.useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  const targetWidth = width && width < 600 ? 125 : 150;
  const categoryWidth = width ? width / (Math.round(width / targetWidth) - 0.5) - 10 : 0;

  return (
    <div className={styles.container} ref={ref}>
      {props.title}
      <div className={styles.slider}>
        {props.relatedItems?.map((product) => {
          return (
            <Link to={GenerateLink.ForProduct(product.id, product.seoName)} key={product.id}>
              <div className={styles.product} style={{ width: categoryWidth }}>
                <div className={product.picture ? styles.image : styles["no-image"]}>
                  <img src={product.picture?.thumbnail?.url || noItem} alt={product.name || undefined} />
                </div>
                <div>{product.name}</div>
                <div>{product.priceAndAvailability.price !== null ? `$${product.priceAndAvailability.price.toFixed(2)}` : null}</div>
              </div>
            </Link>
          );
        })}
        <Link style={{ display: "flex" }} to={props.seeMoreTo}>
          <div className={styles["seeMoreTo"]} style={{ width: categoryWidth }}>
            <p>See More</p>
            <div></div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCarousel;

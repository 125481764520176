import { useMutation } from "@shane32/graphql";
import { AddMachineDocument } from "./AddMachineMutation.g";
import { CheckFitmentDocument } from "./CheckFitment.g";
import { ClearMachineDocument } from "./ClearMachinePictureMutation.g";
import { DeleteMachineDocument } from "./DeleteMachineMutation.g";
import { EditMachineDocument } from "./EditMachineMutation.g";
import { SetMachinePictureDocument } from "./SetMachinePictureMutation.g";
import { SetMachineDocument } from "./SetSelectedMachineMutation.g";

export const FleetMutations = {
  useAddMachine: function () {
    return useMutation(AddMachineDocument);
  },

  useEditMachine: function () {
    return useMutation(EditMachineDocument);
  },

  useDeleteMachine: function () {
    return useMutation(DeleteMachineDocument);
  },

  useSetSelectedMachine: function () {
    return useMutation(SetMachineDocument);
  },

  useSetMachinePicture: function () {
    return useMutation(SetMachinePictureDocument);
  },

  useClearMachinePicture: function () {
    return useMutation(ClearMachineDocument);
  },

  useFitmentCheck: function () {
    return useMutation(CheckFitmentDocument);
  },
};

import { gql } from "@shane32/graphql";
import { FleetQueryQuery, MachineStatuses } from "../gql/graphql";

gql`
  query FleetQuery {
    v1 {
      my {
        selectedGarageItem {
          id
        }
        garage {
          id
          name
          orderItems {
            orderItemGuid
            id
            quantity
            unitPriceExclTax
            reference
            product {
              id
              name
              reviews {
                totalCount
              }
              seoName
              additionalShippingCharge
              manufacturerPartNumber
              manufacturers {
                displayOrder
                manufacturer {
                  displayOrder
                  id
                  name
                  picture {
                    imageUrl
                  }
                }
              }
              priceAndAvailability {
                allowedQuantities
                backorderAvailabilityRange
                oldPrice
                orderMaximumQuantity
                orderMinimumQuantity
                preorderAvailabilityStartDateTime
                price
                status
                stock
                canNotify
              }
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              shipSeparately
              sku
              vendor {
                name
              }
            }
            order {
              createdOnUtc
            }
          }
          hours
          machineAttachment {
            id
            name
          }
          machineEngine {
            id
            name
          }
          machineModel {
            id
            name
            baseName
            picture {
              seoFilename
              mimeType
              thumbnail(size: 100) {
                url
              }
            }
            machineManufacturer {
              id
              name
            }
            machineType {
              id
              name
            }
          }
          notes
          machineStatus
          picture {
            id
            imageUrl
            thumbnail(size: 100) {
              url
            }
          }
          serialNumber
          sortOrder
          stockNumber
          year
          engine
        }
      }
    }
  }
`;

export type IFleet = NonNullable<FleetQueryQuery["v1"]["my"]>["garage"][0];
export type IMachineModel = IFleet["machineModel"];
export type IModelPicture = NonNullable<IMachineModel["picture"]>;
export type IMachineStatusType = MachineStatuses;

export function MachineName(machine: IFleet | null) {
  if (!machine) return "";
  const mfr = machine?.machineModel.machineManufacturer.name;
  const model = machine?.machineModel.name;
  return mfr + " " + model;
}

import { useMutation } from "@shane32/graphql";
import {
  AddAddressDocument,
  AddCouponDocument,
  AddFeedbackDocument,
  AddPaymentDocument,
  AddProductToCartFromCatalogDocument,
  CancelOrderDocument,
  CartSetDestinationExternalPaymentDocument,
  CartSetLineReferenceDocument,
  ChangeFeedbackNameDocument,
  ChangeNameDocument,
  ChangeNumberDocument,
  ChangeQuantityInCartDocument,
  CheckoutDocument,
  CheckoutExternalPaymentDocument,
  DeleteAddressDocument,
  DeletePaymentDocument,
  EditAddressDocument,
  EditPaymentDocument,
  MoveToCartDocument,
  RemoveCouponDocument,
  RemoveProductFromCartDocument,
  SaveForLaterProductDocument,
  SetDefaultAddressDocument,
  SetDefaultPaymentDocument,
  SetSelectedShippingMethodDocument,
  SubmitReturnRequestDocument,
} from "../gql/graphql";
import { CartSetDestinationDocument } from "./CartSetDestinationMutation.g";

export const Mutations = {
  useSetDestination: function () {
    return useMutation(CartSetDestinationDocument);
  },

  useSetDestinationExternalPayment: function () {
    return useMutation(CartSetDestinationExternalPaymentDocument);
  },

  useAddProductToCartFromCatalog: function () {
    return useMutation(AddProductToCartFromCatalogDocument);
  },

  useRemoveProductFromCart: function () {
    return useMutation(RemoveProductFromCartDocument);
  },

  useSaveForLaterProduct: function () {
    return useMutation(SaveForLaterProductDocument);
  },

  useMoveToCart: function () {
    return useMutation(MoveToCartDocument);
  },

  useChangeQuantityInCart: function () {
    return useMutation(ChangeQuantityInCartDocument);
  },

  useSetLineReferenceInCart: function () {
    return useMutation(CartSetLineReferenceDocument);
  },

  useCheckout: function () {
    return useMutation(CheckoutDocument);
  },

  useCheckoutExternalPayment: function () {
    return useMutation(CheckoutExternalPaymentDocument);
  },

  useEditAddress: function () {
    return useMutation(EditAddressDocument);
  },

  useAddAddress: function () {
    return useMutation(AddAddressDocument);
  },

  useDeleteAddress: function () {
    return useMutation(DeleteAddressDocument);
  },

  useSetDefaultAddress: function () {
    return useMutation(SetDefaultAddressDocument);
  },

  useChangeFeedbackName: function () {
    return useMutation(ChangeFeedbackNameDocument);
  },

  useChangeName: function () {
    return useMutation(ChangeNameDocument);
  },

  useChangeNumber: function () {
    return useMutation(ChangeNumberDocument);
  },

  useEditPayment: function () {
    return useMutation(EditPaymentDocument);
  },

  useAddPayment: function () {
    return useMutation(AddPaymentDocument);
  },

  useDeletePayment: function () {
    return useMutation(DeletePaymentDocument);
  },

  useSetDefaultPayment: function () {
    return useMutation(SetDefaultPaymentDocument);
  },

  useSetSelectedShippingMethod: function () {
    return useMutation(SetSelectedShippingMethodDocument);
  },

  useAddFeedback: function () {
    return useMutation(AddFeedbackDocument);
  },

  useCancelOrder: function () {
    return useMutation(CancelOrderDocument);
  },

  useSubmitReturnRequest: function () {
    return useMutation(SubmitReturnRequestDocument);
  },

  useAddCoupon: function () {
    return useMutation(AddCouponDocument);
  },

  useRemoveCoupon: function () {
    return useMutation(RemoveCouponDocument);
  },
};

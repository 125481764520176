import * as React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CategoryCard from "../../components/cards/CategoryCard";
import categoryCardStyles from "../../components/cards/CategoryCard.module.scss";
import GenerateLink from "../../helpers/GenerateLink";
import { ICategoryAndPicture } from "../../queries/CategoriesQuery";
import styles from "./CategoryDisplayMobile.module.scss";

interface IParams {
  categoryId?: string;
}

interface IRouteState {
  seeMore: { show: boolean; id: string | null };
}

interface IProps {
  fullCategories: ICategoryAndPicture[];
}

const CategoryDisplayMobile = (props: IProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const history = useHistory<IRouteState>();
  const params = useParams<IParams>();
  const [width, setWidth] = React.useState<number | null>(null); //null until the div has loaded

  const onResize = React.useCallback(() => {
    if (ref.current) setWidth(ref.current.offsetWidth);
  }, []);
  React.useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  const targetWidth = width && width < 600 ? 70 : 90;
  const categoryWidth = width ? width / (Math.round(width / targetWidth) - 0.5) - 10 : 0;

  const onSeeMore = (show: boolean, id: string | null) => {
    history.push({
      state: {
        seeMore: { show: show, id: id },
      },
    });
  };
  //for homepage, only show categories intended to show on home page
  const homepageCategories = props.fullCategories.filter((y) => y.showOnHomePage);

  //for main page, sort by: display order, alphabetical sorting, id
  const sortedCat = homepageCategories
    .slice(0)
    .sort((a, b) =>
      a.displayOrder > b.displayOrder
        ? 1
        : a.displayOrder < b.displayOrder
        ? -1
        : a.name > b.name
        ? 1
        : a.name < b.name
        ? -1
        : a.id > b.id
        ? 1
        : -1
    );

  const selectedCategoryId = params.categoryId || null;
  const selectedCategory = selectedCategoryId ? props.fullCategories.filter((x) => x.id === selectedCategoryId)[0] || null : null;
  const selectedParentCategoryId: string | null = selectedCategory?.parentCategoryId || null;
  var testDict: { [id: string]: ICategoryAndPicture[] } = {};
  sortedCat
    .filter((x) => x.parentCategoryId === null)
    .forEach((cat) => {
      var secondLevel = sortedCat.filter((x) => x.parentCategoryId === cat.id);
      testDict[cat.id] = sortedCat.filter((x) => secondLevel.find((y) => y.id === x.parentCategoryId) != null);
    });

  return (
    <div className={styles.container} ref={ref}>
      {
        /* don't display if unknown width */ width ? (
          <>
            {history.location.pathname !== "/" ? (
              <Link to={GenerateLink.ForHome()}>
                <div className="image-breadcrumbarrow"></div>All Categories
              </Link>
            ) : null}
            <div className={`${styles.categories} ${categoryCardStyles.homepagecategories}`}>
              <div>
                {sortedCat
                  .filter(
                    (topLevel) =>
                      topLevel.parentCategoryId === null &&
                      (selectedCategoryId
                        ? selectedParentCategoryId
                          ? selectedParentCategoryId === topLevel.id
                          : selectedCategoryId === topLevel.id
                        : true)
                  )
                  .map((topLevel) => (
                    <React.Fragment key={topLevel.id}>
                      <p>{topLevel.name}</p>
                      <div className={history.location.pathname !== "/" ? styles.wrap : ""}>
                        {history.location.state?.seeMore.show && history.location.state?.seeMore.id === topLevel.id
                          ? testDict[topLevel.id].map((thirdLevel) => {
                              return (
                                <CategoryCard
                                  key={thirdLevel.id}
                                  width={categoryWidth}
                                  numberOfProducts={thirdLevel.numberOfProducts ? thirdLevel.numberOfProducts : null}
                                  title={thirdLevel.name}
                                  imageUrl={thirdLevel.picture?.thumbnail?.url || null}
                                  url={GenerateLink.ForSearchCategory(thirdLevel.id, thirdLevel.seoName)}
                                  usingParent
                                />
                              );
                            })
                          : testDict[topLevel.id].slice(0, 10).map((thirdLevel) => {
                              return (
                                <CategoryCard
                                  key={thirdLevel.id}
                                  width={categoryWidth}
                                  numberOfProducts={thirdLevel.numberOfProducts ? thirdLevel.numberOfProducts : null}
                                  title={thirdLevel.name}
                                  imageUrl={thirdLevel.picture?.thumbnail?.url || null}
                                  url={GenerateLink.ForSearchCategory(thirdLevel.id, thirdLevel.seoName)}
                                  usingParent
                                />
                              );
                            })}
                        {testDict[topLevel.id].length >= 10 && history.location.pathname === "/" ? (
                          <Link to={GenerateLink.ForCategory(topLevel.id, topLevel.seoName)} onClick={() => onSeeMore(true, topLevel.id)}>
                            <div
                              style={{
                                width: categoryWidth,
                                height: categoryWidth,
                              }}
                            >
                              <p>See More</p>
                            </div>
                            <div>
                              {topLevel.name} ({testDict[topLevel.id].length - 10})
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default CategoryDisplayMobile;
//.filter(secondLevel => secondLevel.parentCategoryId === topLevel.id && (selectedCategoryId === topLevel.id || ((selectedParentCategoryId ? selectedParentCategoryId === topLevel.id : true) && (selectedCategoryId ? selectedCategoryId === selectedParentCategoryId ? true : selectedCategoryId === secondLevel.id : true))))
//   .map(secondLevel =>
//       <React.Fragment key={secondLevel.id}>
//           {sortedCat
//               .filter(thirdLevel => thirdLevel.parentCategoryId === secondLevel.id)
//               .map(thirdLevel =>
//                   <CategoryCard key={thirdLevel.id} numberOfProducts={thirdLevel.numberOfProducts ? thirdLevel.numberOfProducts : null} title={thirdLevel.name} imageUrl={thirdLevel.picture !== null ? thirdLevel.picture.thumbnail.url : null} url={GenerateLink.ForSearchCategory(thirdLevel.id, thirdLevel.seoName)} usingParent />)
//           }
//       </React.Fragment>)
